import React, { useState, useEffect } from "react";
import "./Main.css";
import PageHeader from "../components/PageHeader";
import NavMain from "../components/NavMain";
import PageFooter from "../components/PageFooter";
import ImageDisplayer from "../components/image/ImageDisplayer";
import ViewImage from "../components/image/ViewImage";

const Main = () => {
  const [collectionType, setCollectionType] = useState("about");
  const [showViewImage, setShowViewImage] = useState(false);
  const [imageToShow, setImageToShow] = useState({
    url: "nothing",
    desc: "nothing",
  });
  const [pageToShow, setPageToShow] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Whenever collectionType changes, reset the page number to 1
    setPageToShow(1);
  }, [collectionType]); // This effect runs whenever `collectionType` changes

  useEffect(() => {
    fetch("/")
      .then((response) => response.text())
      .then((data) => setMessage(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <div className="main-page">
        <PageHeader />
        <NavMain setCollectionType={setCollectionType} />
        {collectionType === "about" ? (
          <>
            <div className="about-page">
              <h1>ABOUT</h1>
              <h2>Nothing :D</h2>
            </div>
          </>
        ) : null}
        {collectionType === "account" ? <></> : null}
        {showViewImage ? (
          <ViewImage
            currentImage={imageToShow}
            setShowViewImage={() => {
              setShowViewImage(false);
            }}
          />
        ) : (
          <ImageDisplayer
            collectionType={collectionType}
            pageToShow={pageToShow}
            setPageToShow={(page) => {
              setPageToShow(page);
            }}
            setShowViewImage={() => {
              setShowViewImage(true);
            }}
            setImageToShow={(image: { url: string; desc: string }) => {
              setImageToShow(image);
            }}
          />
        )}
        <PageFooter />
      </div>
    </>
  );
};

export default Main;
